import { useContext } from 'react';
// import LoadingScreen from 'components/LoadingScreen';
import { getSession } from 'utils/jwt';
import { AuthContext } from "context/AuthContext";

export const PATH = {
    root: "/",
    login: "/Auth/Login",
    register: "/Auth/Register",

    // company: "/MasterData/Company",
    // employee: "/MasterData/Employee",
    // product: "/MasterData/Product",
    // store: "/MasterData/Store",

    sales: "/Transaction/Sales",
    expenses: "/Transaction/Expenses",

};

export const enabledRoute = () => { 
    let returnValue = []
    try {
      const user = getSession();
      // const { user } = useContext(AuthContext);

      let role = {}
      if(!!(user?.enabled_module) && typeof(user?.enabled_module) === "string"){
        role = JSON.parse(user.enabled_module);
      }else if(user?.enabled_module){
        role = user.enabled_module;
      }
      // console.log("enabledRoute", role)

      function loopThroughObjectData(objectData) {
        let itemsValue = [];
        Object.keys(objectData).forEach((key) => {
          if ( objectData[key].hasOwnProperty('children')){
            let obj = {
              title : `${objectData[key].label}`,
              path: `/${key}` , 
              children : loopThroughObjectData(objectData[key].children) ,
            //   icon: switchIcon(key) ,
            }
            
            if( obj.children?.length > 0 ){ 
              itemsValue.push(obj);
            }
          }else if( objectData[key]?.action.R === true ){ // CAN READ
            itemsValue.push({ 
              title: `${objectData[key].label}`, 
              path: `/${key}` ,
              // icon: switchIcon(key) ,
            })
          }
          
        })
        return itemsValue;
      }
      
      returnValue = loopThroughObjectData(role);
    } catch (error) {
      console.log(error);
      returnValue = []
    }
    // console.log("enabledRoute returnValue" , returnValue);
    return returnValue 
}

/*
export const enabledRoute2 = () => { 
    let returnValue = []
    // let itemsValue = []
    try {
      // const user = decodeJWT( localStorage.getItem('accessToken') )
      const { user } = useContext(AuthContext);
      // console.log(JSON.parse(user.enabled_module))
      // const role = []
      // const role = (!!(user?.enabled_module) && typeof(user?.enabled_module) === "string") ? JSON.parse(user.enabled_module) : user.enabled_module
      let role = {}
      if(!!(user?.enabled_module) && typeof(user?.enabled_module) === "string"){
        role = JSON.parse(user.enabled_module);
      }else if(user?.enabled_module){
        role = user.enabled_module;
      }
      // console.log("enabledRoute", role)

      function loopThroughObjectData(objectData) {
        let itemsValue = [];
        Object.keys(objectData).forEach((key) => {
          const CRUD = ["C","R","U","D"];
          const contain_CRUD = CRUD.every((x) => ( x in objectData[key] ) )
          if ( objectData[key] !== null &&  typeof objectData[key] === 'object' &&  !Array.isArray(objectData[key]) &&  !contain_CRUD ){
            itemsValue.push({
                title : `${key}`.replace(/_/gi," "),
                path: `/${key}` , 
                children : loopThroughObjectData(objectData[key]) ,
              //   icon: switchIcon(key) ,
              })
          }else if(objectData[key].R === true){
            itemsValue.push({ 
              title: `${key}`.replace(/_/gi," "), 
              path: `/${key}` , 
              // icon: switchIcon(key) ,
            })
          }
          
        })
        return itemsValue;
      }
      
      returnValue = loopThroughObjectData(role);
    } catch (error) {
      console.log(error);
      returnValue = []
    }
    // console.log("enabledRoute returnValue" , returnValue);
    return returnValue 
}
*/

/*
export const enabledRoute1 = () => { 
    let returnValue = []
    let itemsValue = []
    try {
      const user = decodeJWT( localStorage.getItem('accessToken') )
      // const role = JSON.parse(user.enabled_module)
      const role = typeof(user.enabled_module) === "string" ? JSON.parse(user.enabled_module) : user.enabled_module
    //   console.log("enabledRoute", role)
      
      Object.keys(role).forEach((key) => {
        
        if ( typeof role[key] === 'object' && !Array.isArray(role[key]) && role[key] !== null ){
            // console.log("role[key]" , role[key]);
          let childrenValue = []
          Object.keys(role[key]).forEach((key1) => {
            if(role[key][key1] === true)childrenValue.push({ 
              title: `${key1}`.replace(/_/gi," "), 
              path: `/${key1}` , 
            //   icon: switchIcon(key1) ,
            })
          })
        //   console.log("childrenValue" , childrenValue);
          if(childrenValue.length > 0){
            itemsValue.push({
              title : `${key}`.replace(/_/gi," "),
              path: `/${key}` , 
              children : childrenValue ,
            //   icon: switchIcon(key) ,
            })
          }
        }else if(role[key] === true){
          itemsValue.push({ 
            title: `${key}`.replace(/_/gi," "), 
            path: `/${key}` , 
            // icon: switchIcon(key) ,
          })
        }
        
      })
      returnValue = [{
        title: "Menu",
        children: itemsValue,
      }]
    } catch (error) {
      console.log(error);
      returnValue = []
    }
    // console.log("enabledRoute returnValue" , returnValue);
    return returnValue 
}
*/