import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    
    primary: {
      main: '#273147',
      light: '#2d3852',
      lighter: '#d3ddf5',
      dark: '#212a3c',
      darker: '#1b2232'
    },
    secondary: {
      main: '#693640',
      light: '#b38f96',
      lighter: '#f5d3d9',
      dark: '#5c2832',
      darker: '#4d1e27'
    },

    // primary:{
    //   main: "#827067",
    //   light: "#9c8b82",
    //   lighter: "#b6a79f",
    //   dark: "#68574e",
    //   darker: "#4e3f37"
    // },
    // secondary:{
    //   main: "#67827D",
    //   light: "#829c97",
    //   lighter: "#9fb6b2",
    //   dark: "#4e6863",
    //   darker: "#374e4a"
    // },
    info:{
      main: '#1e405e',
      light: '#234a6c',
      lighter: '#27537a',
      dark: '#1a3650',
      darker: '#152d42'
    },
    success:{
      main: '#375c26',
      light: '#3f6a2c',
      lighter: '#487831',
      dark: '#2f4e20',
      darker: '#27401b',
    },
    warning:{
      main: '#b88749',
      light: '#d49b54',
      lighter: '#efb05f',
      dark: '#9c733e',
      darker: '#815f33'
    },
    error:{
      main: '#8a3650',
      light: '#9f3e5c',
      lighter: '#b34668',
      dark: '#752e44',
      darker: '#612638'
    },
    
    // primary: {
    //   main: '#0b1e9e', 
    //   light: '#aabbff',
    //   lighter: '#adf7ee',
    //   dark: '#091A7A',
    //   darker: '#0a164e',
    // },
    // secondary: {
    //   main: '#b0a8ba',
    //   light: '#ece1f0',
    //   lighter: '#fdf7ff',
    //   dark: '#9d94a8',
    //   darker: '#887e94',
    // },
    // info:{
    //   main: '#4B97C9',    // A medium blue to indicate informational messages
    //   light: '#78B3DC',   // Lighter shade for backgrounds or accents
    //   lighter: '#B8DBF0', // Very light shade for subtle backgrounds
    //   dark: '#3579A3',    // Darker shade for text or emphasis
    //   darker: '#205378',  // The darkest version for strong contrast
    //   contrastText: '#fff' // Use white text for contrast against these shades
    // },
    // success: {
    //   main: '#2E7D32',    // A medium green indicating success
    //   light: '#66BB6A',   // A lighter green for background accents
    //   lighter: '#A5D6A7', // Very light green for subtle success backgrounds
    //   dark: '#1B5E20',    // A darker green for text or emphasis
    //   darker: '#104314',  // The darkest version for the strongest contrast
    //   contrastText: '#fff' // White text to ensure readability
    // },
    // warning: {
    //   main: '#E0A800',    // A warm amber color for warnings
    //   light: '#F1C04D',   // A lighter yellow for background warnings
    //   lighter: '#FBE2A2', // Very light yellow for soft warnings
    //   dark: '#B38400',    // A darker shade for warning text
    //   darker: '#7A5B00',  // The darkest version for deep contrast
    //   contrastText: '#fff' // White text provides good r
    // },
    // error: {
    //   main: '#D32F2F',    // A strong red to indicate errors
    //   light: '#E57373',   // A lighter red for background highlights
    //   lighter: '#F4B8B8', // Very light red for softer error backgrounds
    //   dark: '#B71C1C',    // Darker red for text or emphasis
    //   darker: '#7F0000',  // Deepest red for the strongest contrast
    //   contrastText: '#fff' // White text for contrast against red tones
    // },

    text: {
      primary: '#525860',
      secondary: '#a0a0a0',
    },
  },
  spacing: (value) => value * 8, // 8px grid system
});

export {customTheme};
