import { lazy, Suspense } from 'react';
import { 
    Navigate, 
    useRoutes, 
    // useLocation, 
} from 'react-router-dom';
import AuthGuard from 'guard/AuthGuard';
import GuestGuard from 'guard/GuestGuard';
import NavBarLayout from 'layout/NavBarLayout';
import {enabledRoute} from './paths';
import LoadingScreen from 'components/LoadingScreen';
import { Button } from '@mui/material';

export const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <Suspense fallback={<LoadingScreen/>}>
      <Component {...props} />
    </Suspense>
  );
};

function dynamicImport(path) {
  return import(`../pages${path}`).catch((e) => { console.log('Failed to load component:', e); return ({ default: () => {
    // return(<div/>)
    return(<div style={{paddingLeft:10}}><h1>Halaman Kosong / Ada Pembaruan...</h1> <h6>Tolong cek menu lainnya atau Refresh halaman ini</h6> <Button onClick={()=>{ window.location.reload() }}>Refresh</Button></div>)
  } }); }) 
}

const getNavMenu = () => {
  let navMenu = [];
  const nav = enabledRoute();

  function loopThroughArrayData(arrayData, parentPath = '') {
    let itemData = []
    for (let i = 0; i < arrayData.length; i++) {
      const obj = arrayData[i];
      const fullPath = `${parentPath}${obj.path}`; // .replace(/^\//, '');
      if(obj.hasOwnProperty("children")){
        if(itemData.length === 0){
          itemData.push({ element: <Navigate to={`${fullPath}`} replace />, index: true })
        }
        itemData.push({ 
          path: `${obj.path}`.replace("/","") , 
          fullPath , 
          children : loopThroughArrayData(obj.children , fullPath),
        })
        
      }else{
        // console.log("index getNavMenu",fullPath);
        const ElementObject = Loadable(lazy(() => dynamicImport(`${fullPath}`) ));
        if(itemData.length === 0){
          itemData.push({ element: <Navigate to={`${fullPath}`} replace />, index: true })
        }
        itemData.push({ 
          path: `${obj.path}`.replace("/","")  , 
          fullPath,
          element : <ElementObject/> 
        })
      }
    }
    return itemData;
  }
  
  navMenu = loopThroughArrayData(nav);
  // console.log("getNavMenu",navMenu);
  return navMenu;
};

export default function Router() {
  let navMenu = getNavMenu();
  // console.log("navMenu", navMenu);
  return useRoutes([
    {
      path: 'Auth',
      children: [
        {
          path: 'Login',
          element: (<GuestGuard>
              <Login />
            </GuestGuard>),
        },
        {
          path: 'Register',
          element: (<GuestGuard>
              <Register />
            </GuestGuard>),
        },
      ],
    },
    // Main Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <NavBarLayout />
        </AuthGuard>
      ),
      children: navMenu,
      // children:[
      //   { path: 'Sales', element: <Sales /> }
      // ]
      // [
      //   { element: <Navigate to="/404" replace /> , index: true },
      // ],
    },
    {
      path: '*',
      // element: <LogoOnlyLayout />,
      children: [
      //   { path: 'maintenance', element: <Maintenance /> },
        // { path: '*', element: <Page404 /> },
        { path: '*', element:  <Navigate to="/" replace />  },
      ],
    },
  ]);
}
  
  // AUTHENTICATION
  const Login = Loadable(lazy(() => import('pages/Auth/Login')));
  const Register = Loadable(lazy(() => import('pages/Auth/Register')));
  const Page404 = Loadable(lazy(() => import('pages/Page404'))); 

